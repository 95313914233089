<template>
  <b-row>
    <b-col xs="12" md="4">
      <b-row>
        <b-col xs="6">
          <b-media-aside v-if="order_product.ty_product && order_product.ty_product.images">
            <b-avatar
                v-if="index==0"
                v-for="(image,index) in order_product.ty_product.images"
                :key="image.id"
                :badge="order_product.store_quantity>1?'x'+order_product.store_quantity:''"
                :badge-variant="order_product.store_quantity>1?'danger':''"
                square size="110" :src="image.url"
            />
          </b-media-aside>
        </b-col>
        <b-col xs="6">
          <b-media-aside v-if="order_product.product_id">
            <b-avatar
                v-if="index==0"
                v-for="(image,index) in order_product.product.images"
                :key="image.id"
                :badge="order_product.quantity>1?'x'+order_product.quantity:''"
                :badge-variant="order_product.quantity>1?'danger':''"
                square size="110" :src="siteUrl+image.url"
            />
          </b-media-aside>
        </b-col>
      </b-row>
    </b-col>
    <b-col xs="12" md="8">

      <a v-if="order_product.ty_product && order_product.ty_product.ty_product_list"
         :href="'https://www.trendyol.com/w/w-w-p-'+order_product.ty_product.ty_product_list.product_content_id"
         target="mpProduct"
      >
        <feather-icon icon="ExternalLinkIcon"/>
      </a> &nbsp; &nbsp;
      <span>{{ order_product.id }}</span> &nbsp; &nbsp;
      <b-badge
          v-if="userData.permission.includes('delete-orderproduct')"
          variant="danger"
          @click="deleteOrderProduct"
      >
        <feather-icon icon="TrashIcon"/>
      </b-badge>
      <br>
      <h6 class="mb-0" style="margin-bottom: 0.2rem">
        <b-badge variant="danger">{{ order_product.quantity }}</b-badge>
        x {{ order_product.product_name }}
      </h6>
      <small class="text-muted mr-75">{{ order_product.store_product_name }}</small><br>
      <b-badge>{{ order_product.product_barcode }}</b-badge>
      -
      <b-badge>{{ order_product.merchant_sku }}</b-badge>
      <br>
      <b-badge>{{ order_product.order_product_status }}</b-badge>
      <br>
      <b-row>
        <b-col cols="12">
          {{ order_product.sales_price }} x {{ order_product.quantity }} = {{ order_product.total_sales_price }}<br>
          <b-badge v-if="order_product.discount>0">
            Mağaza İnd.: {{ order_product.discount }}
          </b-badge>
          <b-badge v-if="order_product.mp_discount>0">
            Pazaryeri İnd.: {{ order_product.mp_discount }}
          </b-badge>

          <b-badge variant="warning">Kalan Stok: <b>{{ order_product.product.quantity }}</b></b-badge>
          <b-badge variant="success">{{ order_product.product.purchase_price }} -
            {{ (order_product.product.purchase_price * order_product.quantity).toFixed(2) }}
          </b-badge>
          <b-badge v-if="order_product.ty_product" variant="success">
            Min: {{ order_product.ty_product.price_min }} - Mak: {{ order_product.ty_product.price_max }}
          </b-badge>
        </b-col>
      </b-row>

      <b-row v-if="order_product.ty_product && order_product.ty_product.ty_product_list"
             style=" margin-top:5px">
        <b-col cols="12">
          <b-badge
              v-for="(seller,sellerKey) in order_product.ty_product.ty_product_list.sellers"
              v-if="seller.hasStock"
              :key="sellerKey"
              :variant="seller.merchantName === 'indirimyeri' ? 'info' : ''"
          >{{ seller.merchantName }} ({{ seller.discountedPrice }})
          </b-badge>
          <b-badge>Son Tar. Tar:
            {{ new Date(order_product.ty_product.ty_product_list.buybox_scan_date).toLocaleString('tr-TR') }}
          </b-badge>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'OrderProduct',
  props: {
    order: {
      type: Object,
      required: true,
    },
    order_product: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData'))
  }),
  computed: {
    siteUrl() {
      return process.env.VUE_APP_IMG_URL
    },
  },
  methods: {
    deleteOrderProduct() {
      this.$swal({
        title: 'Ürün Silme',
        text: "Bu ürünü silmek istediğinizden emin misiniz?",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: 'Vazgeç',
        confirmButtonText: "Evet Sil!"
      })
          .then(result => {
            if (result.isConfirmed) {
              this.$store.dispatch('order/deleteOrderProduct', this.order_product.id);
            }
          });

    }
  }
}
</script>

<style scoped>

</style>
